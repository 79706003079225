import router from "@/router";
import ACTION_TYPES from "./action-types";
import MUTATION_TYPES from "./mutation-types";
import GETTER_TYPES from "./getter-types";

export default {
    namespaced: true,
    state: {
        user: undefined,
        routes: {
            login_successful: 'dashboard.home',
        },
    },
    getters: {
        [GETTER_TYPES.API_TOKEN.NAME](state) {
            return ((state.user && state.user.api_token) ? state.user.api_token : undefined);
        },
        [GETTER_TYPES.AUTHENTICATED.NAME](state, getters) {
            return !!getters[GETTER_TYPES.API_TOKEN.NAME];
        },
    },
    mutations: {
        [MUTATION_TYPES.SET_USER](state, value) {
            state.user = value
        },
        [MUTATION_TYPES.SET_ROLES](state, value) {
            state.roles = value
        },
        [MUTATION_TYPES.SET_PERMISSIONS](state, value) {
            state.permissions = value
        },
    },
    actions: {
        [ACTION_TYPES.LOGIN.NAME]({ commit }, data) {
            return new Promise((resolvePromise) => {
                commit(MUTATION_TYPES.SET_USER, data);
                resolvePromise();
            });
        },
        [ACTION_TYPES.LOGOUT.NAME]({ commit }) {
            commit(MUTATION_TYPES.SET_USER, undefined);
            router.push({
                name: 'auth.login',
            });
        },
    },
};
