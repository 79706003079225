import Vue from 'vue'
import Vuetify from 'vuetify'

import 'vuetify/dist/vuetify.min.css'

Vue.use(Vuetify)

export default new Vuetify({
    icons: {
        iconfont: 'mdiSvg', // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
    },
    theme: {
        themes: {
            light: {
                primary: '#2C2C2C',
                secondary: '#383838',
                accent: '#0DB06E',
                error: '#B71C1C',
                background: '#F7F7F7',
                header: '#FBFBFB',
            },
        },
    },
});
