<template>
    <v-app-bar app clipped-left flat height="110" :style="{background: this.$vuetify.theme.themes.light.header}">
        <v-container extended fluid fill-height class="d-flex justify-space-around pa-0 ma-0">
            <v-col cols="4" lg="4" sm="4" class="header-logo fill-height">
                <router-link to="/">
                    <div class="logo"></div>
                </router-link>
            </v-col>

            <v-col cols="5" lg="4" sm="6" fill-height />

            <v-col cols="2" lg="3" sm="2" class="text-right notification">
                <div>
                    <v-menu
                        offset-y
                        :close-on-content-click="false"
                        v-model="inboxOpen"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <div v-on="on" v-bind="attrs" @click.stop="inboxOpen = !inboxOpen">
                                <v-badge
                                    v-if="$store.state.unreadNotificationsCount"
                                    :content="$store.state.unreadNotificationsCount"
                                    left
                                    overlap
                                >
                                    <v-icon>mdi-bell</v-icon>
                                </v-badge>
                                <v-icon v-else>mdi-bell</v-icon>
                            </div>
                        </template>
                        <v-toolbar
                            color="indigo"
                            dark
                        >
                            <v-toolbar-title>Notifications</v-toolbar-title>
                            <v-spacer></v-spacer>
                            <v-menu
                                v-if="$store.state.unreadNotificationsCount"
                                offset-y
                                v-model="inboxActionsOpen"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn icon @click="inboxActionsOpen = !inboxActionsOpen">
                                        <v-icon>mdi-dots-vertical</v-icon>
                                    </v-btn>
                                </template>
                                <v-list>
                                    <v-list-item href="#" @click="clearAllNotifications">
                                        <v-list-item-content>
                                            <v-list-item-title>Clear All</v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </v-toolbar>
                        <v-list class="header-dropdown" v-if="notifications?.length">
                            <v-list-item
                                v-for="notification in notifications"
                                :key="notification.id"
                                two-line
                                :to="getNotificationLink(notification)"
                                @click="markNotificationAsRead(notification)"
                            >
                                <v-list-item-content>
                                    <v-list-item-title>
                                        {{ notification.subject }}
                                    </v-list-item-title>
                                    <v-list-item-subtitle>
                                        {{ (new Date(notification.created_at)).toLocaleString() }}
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                        <v-list class="header-dropdown" v-else>
                            <v-list-item>
                                You have no notifications.
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </div>
            </v-col>
        </v-container>
    </v-app-bar>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import authActionTypes from "@/store/modules/auth/action-types";
import storeActionTypes from "@/store/action-types";
import storeGetterTypes from "@/store/getter-types";

export default {
    data () {
        return {
            inboxOpen: false,
            inboxActionsOpen: false,
        }
    },
    computed: {
        ...mapGetters({
            notifications: storeGetterTypes.NOTIFICATIONS_UNREAD.PATH,
        }),
    },
    methods: {
        ...mapActions({
            signOut: authActionTypes.LOGOUT.PATH,
        }),
        getNotificationLink (notification) {
            if(notification.listing_offers?.length) {
                return {
                    name: 'dashboard.offers.edit',
                    params: {
                        id: notification.listing_offers[0].id,
                    },
                };
            } else if (notification.listings?.length) {
                return {
                    name: 'dashboard.items.edit',
                    params: {
                        id: notification.listings[0].id,
                    },
                };
            }
            return null;
        },
        markNotificationAsRead (notification) {
            this.$store.dispatch(storeActionTypes.NOTIFICATION_MARK_AS_READ.PATH, notification.id);
            this.inboxOpen = false;
        },
        clearAllNotifications () {
            this.$store.dispatch(storeActionTypes.NOTIFICATIONS_MARK_ALL_AS_READ.PATH);
        },
        handleLogout (event) {
            event.preventDefault();
            axios.post(this.route('logout'))
                .then(({ data }) => {
                    this.signOut();
                })
        },
    },
    mounted () {
        this.$store.dispatch(storeActionTypes.GET_NOTIFICATIONS.PATH, {filter: 'unread'});
        setTimeout(() => {
            this.$store.dispatch(storeActionTypes.GET_NOTIFICATIONS.PATH, {filter: 'unread'});
        }, this.$store.state.notificationsTtl);
    },
}
</script>

