export default {
    ADD_ALERT: 'ADD_ALERT',
    REMOVE_ALERT: 'REMOVE_ALERT',
    SET_NOTIFICATIONS: {
        NAME: 'SET_NOTIFICATIONS',
        PATH: 'SET_NOTIFICATIONS',
    },
    SET_UNREAD_NOTIFICATIONS_COUNT: {
        NAME: 'SET_UNREAD_NOTIFICATIONS_COUNT',
        PATH: 'SET_UNREAD_NOTIFICATIONS_COUNT',
    },
    SET_NOTIFICATIONS_UPDATED_AT: {
        NAME: 'SET_NOTIFICATIONS_UPDATED_AT',
        PATH: 'SET_NOTIFICATIONS_UPDATED_AT',
    },
};
