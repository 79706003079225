/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

import './vuetify/variables.scss';

import Vue from 'vue'
import vuetify from '@/vuetify'
import router from "@/router";
import store from "@/store";
import App from '@/views/App';
import { ZiggyVue } from 'ziggy';
import { Ziggy } from './ziggy';
import qfPermissionsPlugin from '@/vue-plugins/permissions';
import '@/axios/interceptors';

import { TiptapVuetifyPlugin } from 'tiptap-vuetify';
import 'tiptap-vuetify/dist/main.css';
Vue.use(ZiggyVue, Ziggy);
Vue.use(qfPermissionsPlugin);

const app = new Vue({
    vuetify,
    router,
    store,
    render: h => h(App),
    el: '#app',
});

Vue.use(TiptapVuetifyPlugin, {
    vuetify,
    iconsGroup: 'mdi'
})
