import authGetterTypes from "./getter-types";
import {mapGetters, mapState} from "vuex";

export default {
    computed: {
        ...mapState({
            currentUser: state => state.auth.user,
            currentUserApiToken: state => state.auth.apiToken,
        }),
        ...mapGetters({
            authenticated: authGetterTypes.AUTHENTICATED.PATH,
            currentUserApiToken: authGetterTypes.API_TOKEN.PATH,
        }),
    },
}
