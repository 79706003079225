<template>
    <v-navigation-drawer
        app
        clipped
        permanent
        width="408"
    >
        <v-list nav class="pa-0">
            <div v-for="(item, itemIndex) in filteredNavItems" :key="itemIndex">
                <v-list-item
                    v-if="!item.subLinks"
                    :to="item.link"
                    :exact="true"
                    dark
                    color="primary"
                    link
                    white
                >
                    <v-list-item-icon>
                        <v-icon>{{ item.icon }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>{{ item.title }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <v-list-group
                    v-else
                    :prepend-icon="item.icon"
                    :to="item.link"
                    :exact="true"
                    dark
                    color="primary"
                    link
                    white
                >
                    <template v-slot:activator>
                        <v-list-item-title>{{ item.title }}</v-list-item-title>
                    </template>

                    <v-list-item
                        v-for="sublink in item.subLinks"
                        :to="sublink.link"
                        :key="sublink.title"
                        :exact="true"
                        color="primary"
                        link
                        class="sub-menu-item"
                    >
                        <v-list-item-title>{{ sublink.title }}</v-list-item-title>
                    </v-list-item>
                </v-list-group>
            </div>
        </v-list>

        <template v-slot:append>
            <v-list nav class="pa-7">
                <v-list-item @click="handleLogout" :ripple="false">
                    <v-list-item-title><span>Log Out</span></v-list-item-title>
                </v-list-item>
                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-title class="sellier"><span>&copy; SELLIER KINGHTSBRIDGE</span></v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </template>
    </v-navigation-drawer>
</template>

<script>
import authActionTypes from "@/store/modules/auth/action-types";
import authMixin from "@/store/modules/auth/component-mixin";

export default {
    mixins: [
        authMixin,
    ],
    data () {
        return {
            navItems: [
                {
                    title: 'Dashboard',
                    icon: 'mdi-view-dashboard',
                    link: { name: 'dashboard.home' }
                },{
                    title: 'Merchants',
                    icon: 'mdi-account-multiple',
                    link: { name: 'dashboard.merchants.index' },
                    canShow: this.is("Admin"),
                    subLinks: [
                        {
                            title: 'Active Merchants',
                            link: { name: 'dashboard.merchants.index', query: {filter: 'active'} },
                        },{
                            title: 'Archived Merchants',
                            link:  { name: 'dashboard.merchants.index', query: {filter: 'archived'} },
                        },{
                            title: 'Starred Merchants',
                            link: { name: 'dashboard.merchants.index', query: {filter: 'flagged'} },
                        },
                    ]
                },{
                    title: 'Listings',
                    icon: 'mdi-tag',
                    link: { name: 'dashboard.items.index' },
                    subLinks: [
                        {
                            title: 'With Merchant',
                            link: { name: 'dashboard.items.index', query: {filter: 'with_merchant'} },
                        },{
                            title: 'With Sellier',
                            link: { name: 'dashboard.items.index', query: {filter: 'with_sellier'} },
                        },{
                            title: 'Selling & Sold',
                            link: { name: 'dashboard.items.index', query: {filter: 'selling_sold'} },
                        },{
                            title: 'Live But Merchant Away',
                            link: { name: 'dashboard.items.index', query: {filter: 'live_away_merchant'} },
                        },{
                            title: 'Archive',
                            link: { name: 'dashboard.items.index', query: {filter: 'archive'} },
                        },
                    ]
                },{
                    title: 'Offers',
                    icon: 'mdi-currency-usd',
                    link: { name: 'dashboard.offers.index' },
                    subLinks: [
                        {
                            title: 'All Offers',
                            link: { name: 'dashboard.offers.index' },
                        },
                    ]
                },
            ],
            account: {
                title: 'Account',
                icon: 'mdi-cog',
                link: { name: 'dashboard.account' }
            },
        }
    },
    computed: {
        filteredNavItems () {
            return this.navItems.filter(navItem => navItem.canShow === undefined || navItem.canShow);
        },
    },
    methods: {
        handleLogout (event) {
            event.preventDefault();
            this.$store.dispatch(authActionTypes.LOGOUT.PATH);
        },
    },
}
</script>
