<template>
    <v-app>
        <NavBar v-if="authenticated" />
        <router-view />
        <v-snackbar
            v-for="alert in this.$store.state.alerts" :key="alert.uuid"
            v-model="alert.visible"
            multi-line
            :color="alert.type"
        >
            {{ alert.text }}
            <template v-slot:action>
                Dismiss
            </template>
        </v-snackbar>
    </v-app>
</template>

<script>
import NavBar from '@/components/NavBar';
import authMixin from "@/store/modules/auth/component-mixin";

export default {
    mixins: [
        authMixin,
    ],
    components: {
        NavBar,
    },
}
</script>
