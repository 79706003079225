import Vue from 'vue';
import VueRouter from 'vue-router';
import Dashboard from '@/views/Dashboard';
import store from "@/store";
import authGetterTypes from "@/store/modules/auth/getter-types";

Vue.use(VueRouter);
const routes = [
    {
        path: '/',
        redirect: {
            name: 'dashboard.home'
        }
    },{
        path: '/auth/login',
        name: 'auth.login',
        component: () => import('@/views/Auth/Login'),
        meta: {
            middleware: "guest",
            breadcrumb: 'Login',
        },
    },{
        path: '/auth/forgot-password',
        name: 'auth.forgot-password',
        component: () => import('@/views/Auth/ForgotPassword'),
        meta: {
            middleware: "guest",
            breadcrumb: 'Forgot Password',
        },
    },{
        path: '/auth/reset-password',
        name: 'auth.reset-password',
        component: () => import('@/views/Auth/ResetPassword'),
        meta: {
            middleware: "guest",
            breadcrumb: 'Reset Password',
        },
    },{
        path: '/dashboard',
        name: 'dashboard',
        component: Dashboard,
        meta: {
            middleware: "auth",
            breadcrumb: 'Dashboard'
        },
        children: [
            {
                path: '/dashboard/home',
                name: 'dashboard.home',
                component: () => import('@/views/Dashboard/Home'),
                meta: {
                    middleware: "auth",
                    breadcrumb: 'Home',
                },
            },{
                path: '/dashboard/merchants',
                name: 'dashboard.merchants.index',
                component: () => import('@/views/Dashboard/Merchants/Index'),
                meta: {
                    middleware: "auth",
                    breadcrumb: 'Merchants',
                },
            },{
                path: '/dashboard/merchants/:id',
                name: 'dashboard.merchants.edit',
                component: () => import('@/views/Dashboard/Merchants/Edit'),
                meta: {
                    middleware: "auth",
                    breadcrumb: 'Merchant Profile',
                },
            },{
                path: '/dashboard/items',
                name: 'dashboard.items.index',
                component: () => import('@/views/Dashboard/Items/Index'),
                meta: {
                    middleware: "auth",
                    breadcrumb: 'Items',
                },
            },{
                path: '/dashboard/items/:id',
                name: 'dashboard.items.edit',
                component: () => import('@/views/Dashboard/Items/Edit'),
                meta: {
                    middleware: "auth",
                    breadcrumb: 'Edit Listing',
                },
            },{
                path: '/dashboard/offers',
                name: 'dashboard.offers.index',
                component: () => import('@/views/Dashboard/Offers/Index'),
                meta: {
                    middleware: "auth",
                    breadcrumb: 'Offers',
                },
            },{
                path: '/dashboard/offers/:id',
                name: 'dashboard.offers.edit',
                component: () => import('@/views/Dashboard/Offers/Edit'),
                meta: {
                    middleware: "auth",
                    breadcrumb: 'Edit Offer',
                },
            },
        ],
    },
];

let router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
});

router.beforeEach((to, from, next) => {
    let siteName = process.env.MIX_APP_NAME ?? 'Sellier Dashboard';
    document.title = `${to.meta.breadcrumb} – ${siteName}`
    if(to.meta.middleware === "guest") {
        if(store.getters[authGetterTypes.AUTHENTICATED.PATH]) {
            next({name: "dashboard.home" });
        }
        next();
    } else {
        if(store.getters[authGetterTypes.AUTHENTICATED.PATH]) {
            next();
        } else {
            next({name: "auth.login" });
        }
    }
})

export default router;
