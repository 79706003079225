export default {
    ADD_ALERT: {
        NAME: 'add_alert',
        PATH: 'add_alert',
    },
    GET_NOTIFICATIONS: {
        NAME: 'get_notifications',
        PATH: 'get_notifications',
    },
    NOTIFICATION_MARK_AS_READ: {
        NAME: 'NOTIFICATION_MARK_AS_READ',
        PATH: 'NOTIFICATION_MARK_AS_READ',
    },
    NOTIFICATIONS_MARK_ALL_AS_READ: {
        NAME: 'NOTIFICATIONS_MARK_ALL_AS_READ',
        PATH: 'NOTIFICATIONS_MARK_ALL_AS_READ',
    },
};
