export default {
    API_TOKEN: {
        NAME: 'api_token',
        PATH: 'auth/api_token',
    },
    AUTHENTICATED: {
        NAME: 'authenticated',
        PATH: 'auth/authenticated',
    },
};
