import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist'
import auth from './modules/auth';
import ACTION_TYPES from "./action-types";
import MUTATION_TYPES from "./mutation-types";
import GETTER_TYPES from "./getter-types";
import { v4 as uuidv4 } from 'uuid';

Vue.use(Vuex);

export default new Vuex.Store({
    plugins: [
        new VuexPersistence({
            reducer: rootState => ({
                auth: rootState.auth,
            }),
        }).plugin,
    ],
    modules: {
        auth,
    },
    state: {
        alerts: [],
        listingStatusOptions: [
            {value: 'Pending Review', text: 'Pending Review'},
            {value: 'Rejected', text: 'Rejected'},
            {value: 'Awaiting Approval', text: 'Awaiting Approval'},
            {value: 'Awaiting Release', text: 'Awaiting Release'},
            {value: 'Live', text: 'Live'},
            {value: 'Paused', text: 'Paused'},
            {value: 'Offer Accepted (Awaiting Payment)', text: 'Offer Accepted (Awaiting Payment)'},
            {value: 'Sold (Awaiting Postage)', text: 'Sold (Awaiting Postage)'},
            {value: 'Posted', text: 'Posted'},
            {value: 'Received (Awaiting Appraisal)', text: 'Received (Awaiting Appraisal)'},
            {value: 'Appraised', text: 'Appraised - Accepted'},
            {value: 'Complete', text: 'Complete'},
            {value: 'Withdrawn', text: 'Withdrawn'},
        ],
        notifications: {},
        notificationsUpdatedAt: null,
        notificationsTtl: (15*60*1000), // 15min in milliseconds
        unreadNotificationsCount: null,
    },
    getters: {
        [GETTER_TYPES.NOTIFICATIONS_UNREAD.NAME] (state) {
            return Object.values(state.notifications).filter(notification => !notification?.read_at);
        },
    },
    mutations: {
        [MUTATION_TYPES.ADD_ALERT](state, value) {
            state.alerts.push(value);
        },
        [MUTATION_TYPES.REMOVE_ALERT](state, alertUuid) {
            state.alerts = state.alerts.filter((alert) => {
                return alert.uuid !== alertUuid;
            });
        },
        [MUTATION_TYPES.SET_NOTIFICATIONS.NAME] (state, value) {
            console.log("SET_NOTIFICATIONS", value);
            state.notifications = Object.assign({}, state.notifications, value);
        },
        [MUTATION_TYPES.SET_UNREAD_NOTIFICATIONS_COUNT.NAME] (state, value) {
            state.unreadNotificationsCount = value;
        },
        [MUTATION_TYPES.SET_NOTIFICATIONS_UPDATED_AT.NAME] (state, value) {
            state.notificationsUpdatedAt = value;
        },
    },
    actions: {
        [ACTION_TYPES.ADD_ALERT.NAME]({ commit }, alertData) {
            const newAlert = Object.assign({
                uuid: uuidv4(),
                visible: true,
                timeout: 2000,
            }, alertData);
            commit(MUTATION_TYPES.ADD_ALERT, newAlert);
            setTimeout(() => {
                commit(MUTATION_TYPES.REMOVE_ALERT, newAlert.uuid);
            }, newAlert.timeout + 500)
        },

        [ACTION_TYPES.GET_NOTIFICATIONS.NAME] ({ commit }, argumentPayload) {
            return new Promise((resolvePromise, rejectPromise) => {
                const payload = Object.assign({
                        useCache: true,
                        page: 1,
                        per_page: 12,
                    }, argumentPayload),
                    params = Object.assign({}, payload);

                delete params.useCache;
                axios.get(`notifications`, { params })
                    .then(
                        async ({ data }) => {
                            const notificationsById = {};
                            data.data.forEach(brand => {
                                notificationsById[brand.id] = brand;
                            })
                            await commit(MUTATION_TYPES.SET_NOTIFICATIONS.NAME, notificationsById);
                            if (params.filter && params.filter === 'unread') {
                                await commit(MUTATION_TYPES.SET_UNREAD_NOTIFICATIONS_COUNT.NAME, data.total);
                                if (data.current_page === 1) {
                                    await commit(MUTATION_TYPES.SET_NOTIFICATIONS_UPDATED_AT.NAME, Date.now());
                                }
                            }
                            resolvePromise(data);
                        },
                        function () {
                            rejectPromise(...arguments);
                        }
                    );
            });
        },

        [ACTION_TYPES.NOTIFICATION_MARK_AS_READ.NAME] ({ commit, state }, notificationId) {
            const notification = state.notifications[notificationId];
            if(!notification?.read_at) {
                axios.post(`notifications/${notification.id}/mark-as-read`).then(
                    ({ data }) => {
                        commit(MUTATION_TYPES.SET_NOTIFICATIONS.PATH, {
                            [data.id]: data,
                        });
                        commit(MUTATION_TYPES.SET_UNREAD_NOTIFICATIONS_COUNT.PATH,
                            state.unreadNotificationsCount
                                ? (state.unreadNotificationsCount - 1)
                                : 0
                        );
                    }
                );
            }
        },

        [ACTION_TYPES.NOTIFICATIONS_MARK_ALL_AS_READ.NAME] ({ commit }) {
            axios.post(`notifications/mark-all-as-read`).then(
                ({ data }) => {
                    commit(MUTATION_TYPES.SET_NOTIFICATIONS.PATH, (() => {
                        const notifications = {};
                        data.forEach(notification => {
                            notifications[notification.id] = notification;
                        })
                        return notifications;
                    })());
                    commit(MUTATION_TYPES.SET_UNREAD_NOTIFICATIONS_COUNT.PATH, 0);
                }
            );
        },
    },
});
