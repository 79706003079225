import axios from 'axios';
import store from '@/store';
import authActionTypes from '@/store/modules/auth/action-types';
import authGetterTypes from "@/store/modules/auth/getter-types";

/**
 * ---------------------------------------------------------------------------------------------------------------------
 * Quickfire Axios Defaults
 * @see: https://axios-http.com/docs/config_defaults
 */
axios.defaults.baseURL = window.api_endpoint
axios.defaults.headers.common['Content-Type'] = 'application/json';

if(store.state.auth.user && store.state.auth.user.api_token) {
    Object.assign(axios.defaults.headers.common, {
        Authorization: `Bearer ${store.state.auth.user.api_token}`,
    });
} else {
    delete axios.defaults.headers.common.Authorization;
}

store.watch(
    (state, getters) => getters[authGetterTypes.API_TOKEN.PATH],
    (newApiTokenValue) => {
        if(newApiTokenValue) {
            Object.assign(axios.defaults.headers.common, {
                Authorization: `Bearer ${newApiTokenValue}`,
            });
        } else {
            delete axios.defaults.headers.common.Authorization;
        }
    }
);

/**
 * Quickfire Axios Interceptors
 * @see: https://axios-http.com/docs/interceptors
 */

/**
 * If any request returns "401 Unauthorized", it's likely that either the CSRF token has expired, or the session has expired.
 * If just the token, refresh it.
 * If the session, log user out of SPA, and redirect to login page.
 * @todo: Implement token refresh. Currently only log out.
 */
axios.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    if(error.response.status === 401) {
        store.dispatch(authActionTypes.LOGOUT.PATH);
    }
    return Promise.reject(error);
});
