<template>
    <div>
        <NavDrawer />
        <v-main class="ml-11" :style="{background: this.$vuetify.theme.themes.light.background}">
            <v-container class="mt-10" fluid>
                <router-view class="px-7" />
            </v-container>
        </v-main>
    </div>
</template>

<script>
import NavDrawer from '@/components/NavDrawer';

export default {
    components: {
        NavDrawer,
    },
}
</script>
